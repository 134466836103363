<template>
  <div class="container-fluid text-left">
    <div class="row">
      <div class="card-body pt-0">
        <!-- <div class="row" v-if="!isZoom">
          <div class="col-sm-12 pl-2 pr-3">
            <button
              type="button"
              class="btn float-right"
              style="border: 1px solid #F2404D; padding: 4px 30px;"
              v-b-modal.modal-confirm-absence
            >Báo vắng</button>
          </div>
        </div> -->
        <div class="row p-0 mt-0">
          <div
            :class="isZoom ? 'col-sm-12 p-0' : 'col-sm-8 p-0 pt-2'"
            style="border-right: 1px solid #e5e5e5;"
          >
            <div v-if="!isZoom" class="pl-2">
              <div style="display: inline">
                <strong>{{event.name}}</strong>
                <div class="float-right" style="float: right">
                  <button
                    v-if="!GET_CURRENT_ACCOUNT.attendance"
                    type="button"
                    class="btn float-right"
                    style="border: 1px solid #F2404D; padding: 3px 30px; margin-right: 5px; border-radius: 10px;"
                    v-b-modal.modal-confirm-absence
                  >{{$t('information.reasonAbsence')}}</button>
                  <button
                    v-if="checkCurrentUserNoAttendance"
                    type="button"
                    class="btn float-right"
                    style="border: 1px solid #F2404D; padding: 3px 15px; margin-right: 5px; border-radius: 10px;"
                    @click="onClickAttendance"
                  >{{$t('attendance.title')}}</button>
                  <button
                    type="button"
                    class="btn float-right"
                    style="border: 1px solid #F2404D; padding: 3px 15px; margin-right: 5px; border-radius: 10px;"
                    @click="onGoToSummary"
                  >{{$t('sideBar.summary')}}</button>
                </div>
              </div>
              <div class="location mt-3 pt-2 pb-4">
                <p>
                  <img src="../../assets/icons/path-94.svg" />
                  <span class="pl-1">{{formatEventDateTime(event.start_date, event.end_date)}}</span>
                </p>
                <div v-if="event.venue != null && event.venue != ''">
                  <p>
                    <img src="../../assets/icons/path-95.svg" />
                    <span class="pl-1">{{event.venue}}</span>
                  </p>
                </div>
                
                <div v-if="event.organization_data != null && event.organization_data != ''">
                  <p>
                    <img src="../../assets/icons/user-tie.svg" /><span class="pl-1">{{event.organization_data}}</span>
                  </p>
                </div>                
              </div>
            </div>
            <div>
              <div style="position: relative">
                <!-- Meeting title -->
                <div class="card-header p-2 font-weight-bold">{{$t('information.meetingContent')}}</div>
                <!-- Expand -->
                <div class="p-2" style="position: absolute; top: 0; right: 0;" @click="onExpand()">
                  <div v-show="!isZoom" style="cursor: pointer;">
                    <i class="fas fa-expand expand-color"></i>
                  </div>
                  <div v-show="isZoom" style="cursor: pointer;">
                    <i class="fas fa-compress expand-color"></i>
                  </div>
                </div>
              </div>
              <div class="p-2" style="display: flex; justify-content: space-between;">
                <p
                  class="text-justify pre-text"
                  style="margin-bottom: 0; font-size: 14px;"
                >{{event.discription}}</p>
              </div>
            </div>
            <div class="pdf">
              <div class="pdf_file">
                <img 
                  v-if="event.invitation_url!=null && event.invitation_url!=''" 
                  :src="event.invitation_url"
                  class="invitation_img"
                  alt="Responsive image"
                  @click="onClickImg"
                >
                <div v-else class="p-2 font-italic font-weight-bold" style="font-size: 14px">{{$t('information.notInvitationInfo')}}</div>
                <!-- <pdf :src="pdf_url"></pdf> -->
              </div>
            </div>
          </div>
          <div v-show="!isZoom" class="col-sm-4 p-0">
            <attendance-list-dialog></attendance-list-dialog>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <b-modal
      :title="$t('reasonAbsence.title')"
      centered
      id="modal-confirm-absence"
      ref="modal-confirm-absence"
    >
      <div class="container">
        <div class="row p-2">
          <textarea
            class="form-control p-1"
            cols="45"
            rows="5"
            :placeholder="$t('reasonAbsence.title')"
            v-model="reasonAbsence.description"
          />
        </div>
      </div>
      <template v-slot:modal-footer>
        <div class="w-100 pl-2 pr-2">
          <b-button
            variant="primary"
            size="sm"
            class="send-reason-absence float-right pl-4 pr-4"
            @click="saveReasonAbsence"
          >{{$t('reasonAbsence.sendReasonAbsence')}}</b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right mr-2 pl-4 pr-4"
            style="background-color: #BDBDBD; border: 1px solid #ffffff;"
            @click="$bvModal.hide('modal-confirm-absence')"
          >{{ $t('common.close') }}</b-button>
        </div>
      </template>
    </b-modal>
    <!--  -->
    <b-modal
      :title="$t('attendance.title')"
      centered
      id="modal-user-attendance"
      ref="modal-user-attendance"
    >
      <div class="row">
        <div class="col-md-12 mt-2 mb-4">
          {{$t('attendance.confirmUserAttendance')}}
        </div>
      </div>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="send-reason-absence float-right pl-4 pr-4"
            @click="onSaveAttendance"
          >{{$t('common.confirm')}}</b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-left mr-2 pl-4 pr-4"
            style="background-color: #BDBDBD; border: 1px solid #ffffff;"
            @click="$bvModal.hide('modal-user-attendance')"
          >{{ $t('common.close') }}</b-button>
        </div>
      </template>
    </b-modal>
    <!--  -->
    <snack-bar v-for="snackbar in GET_SNACK_BAR" :key="snackbar.id" :item="snackbar"></snack-bar>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import AttendanceListDialog from "Components/AttendanceListDialog";
import moment from "moment";
import functionUtils from "utils/functionUtils"
import Enum from 'enum/enum';
import TemplateCategoriesEnum from 'enum/templateCategoriesEnum'
// import pdf from 'vue-pdf'
// import TabType from 'enum/tabType'
export default {
  components: {
    "attendance-list-dialog": AttendanceListDialog,
    // pdf: pdf
  },
  data: () => ({
    event: {
      name: "",
      venue: "",
      discription: "",
      start_date: null,
      end_date: null,
      invitation_url: null,
      organization_data: null
    },
    attendees: [],
    // documentList: [],
    // pdf_url: "",
    isZoom: false,
    isShow: false,
    event_id: null,
    reasonAbsence: {
      id: 0,
      description: ""
    },
    checkCurrentUserNoAttendance: false,
    forICTDongnai: process.env.VUE_APP_IS_FOR_DONGNAI === "true"
  }),
  watch: {
    EVENT_DETAIL_DATA() {
      this.event.name = this.EVENT_DETAIL_DATA.name;
      this.event.venue = this.EVENT_DETAIL_DATA.venue;
      this.event.discription = this.EVENT_DETAIL_DATA.title;
      let start_date = moment(this.EVENT_DETAIL_DATA.start_datetime).format('YYYY/MM/DD HH:mm');
      let end_date = moment(this.EVENT_DETAIL_DATA.end_datetime).format('YYYY/MM/DD HH:mm');

      this.event.start_date = start_date
      this.event.end_date = end_date
      
      this.event.invitation_url = this.EVENT_DETAIL_DATA.invitation_url;
      this.event.organization_data = this.EVENT_DETAIL_DATA.organization_data.name;
      document.title = this.projectTitle(this.EVENT_DETAIL_DATA)
    },
    GET_REASON_ABSENCE_DETAIL_DATA() {
      this.reasonAbsence.id = this.GET_REASON_ABSENCE_DETAIL_DATA.id;
      this.reasonAbsence.description =this.GET_REASON_ABSENCE_DETAIL_DATA.description;
    }
  },
  computed: {
    ...mapGetters([
      "EVENT_DETAIL_DATA",
      "GET_SNACK_BAR",
      "GET_REASON_ABSENCE_DETAIL_DATA",
      "GET_CURRENT_ACCOUNT"
    ])
  },
  methods: {
    // 
    formatEventDateTime: function(startTime, endTime){
      return functionUtils.formatEventDateTime(startTime, endTime)
    },
    /**
     * On expand
     */
    onExpand: function() {
      this.isZoom = !this.isZoom;
    },
    compareDate: function(start, end) {
      if (start.format("DD-MM-YYYY") === end.format("DD-MM-YYYY")) {
        return start.format("DD/MM/YYYY");
      // } else if (start.format("MM-YYYY") === end.format("MM-YYYY")) {
      //   return start.format("DD") + " - " + end.format("DD/MM/YYYY");
      // } else if (start.format("YYYY") === end.format("YYYY")) {
      //   return start.format("DD/MM") + " - " + end.format("DD/MM/YYYY");
      } else return start.format("DD/MM/YYYY") + " - " + end.format("DD/MM/YYYY");
    },
    saveReasonAbsence: function() {
      
      if ((this.reasonAbsence.description == "") | (this.reasonAbsence.description == null)) {
        this.ON_SHOW_TOAST({
          message: "Vui lòng Nhập "+ this.$t('reasonAbsence.title'),
          styleType: "danger"
        });
      } else {
        //
        this.$refs["modal-confirm-absence"].hide();
        let data = {
          id: this.reasonAbsence.id,
          reason_absence: this.reasonAbsence.description,
          event_id: this.event_id
        };
        if (this.reasonAbsence.id==0){
          this.ADD_REASON_ABSENCE(data)
          .then(
            function() {
              this.sendNotificationReason()
              this.sendEmail()
              this.ON_SHOW_TOAST({
                message: "Success",
                styleType: "success"
              });
            }.bind(this)
          )
          .catch(
            function(error) {
              this.ON_SHOW_TOAST({
                message: error.response.data.message,
                styleType: "danger"
              });
            }.bind(this)
          );
        }else{
          this.UPDATE_REASON_ABSENCE(data)
          .then(
            function() {
              this.sendNotificationReason()
              this.sendEmail()
              this.ON_SHOW_TOAST({
                message: "Success",
                styleType: "success"
              });
            }.bind(this)
          )
          .catch(
            function(error) {
              this.ON_SHOW_TOAST({
                message: error.response.data.message,
                styleType: "danger"
              });
            }.bind(this)
          );
        }
      }
    },
    onGoToSummary(){
      this.$router.push({
        name: "summary.title",
      });
    },
    projectTitle: function(event) {
      const prefix_title = this.forICTDongnai ? "" : "[a' Meeting] - "
      let defaultTitle = `${prefix_title}${this.$t('common.appTitle')}`
      if (event){
          return `${defaultTitle} | ${event.name}`
      }
      return `${defaultTitle}`
    },
    sendNotificationReason(){
      let userReason = this.GET_CURRENT_ACCOUNT.lastname + " " + this.GET_CURRENT_ACCOUNT.firstname;
      let data = {
        event_id: this.event_id,
        // message_title: this.event.name,
        // message_body: this.$t('notification.attendance.reasonNotification', {'0': userReason, '1': this.reasonAbsence.description}),
        user_id: this.GET_CURRENT_ACCOUNT.id,
        send_chairmain_and_secretary: true,
        absence_content: this.reasonAbsence.description,
        user_absence_name: userReason,
        action_code: TemplateCategoriesEnum.REASON_ABSENCE,
        template_type: Enum.TemplateType.Notification,
        notification_type_code: Enum.NotificationType.ReasonAbsence
      }
      this.SEND_NOTIFICATION_IN_MEETING(data)

      // send sms
      // let smsContent = this.$t('notification.attendance.reasonNotification', {'0': userReason, '1': this.reasonAbsence.description})

      let smsData = {
        event_id: this.event_id,
        // content: functionUtils.uniDecodeString(smsContent),
        user_id: this.GET_CURRENT_ACCOUNT.id,
        send_chairmain_and_secretary: true,
        absence_content: this.reasonAbsence.description,
        user_absence_name: userReason,
        action_code: TemplateCategoriesEnum.REASON_ABSENCE,
        template_type: Enum.TemplateType.SMS
      }
      this.SEND_SMS_IN_MEETING(smsData)
    },
    onClickImg(){
      if (this.event.invitation_url!="" && this.event.invitation_url!=null){
        window.open(this.event.invitation_url);
      }
    },
    sendEmail: function(){
      let userReason = this.GET_CURRENT_ACCOUNT.lastname + " " + this.GET_CURRENT_ACCOUNT.firstname;
      let data = {
        action_code: TemplateCategoriesEnum.REASON_ABSENCE,
        template_type: Enum.TemplateType.Email,
        event_id: this.event_id,
        user_id: this.GET_CURRENT_ACCOUNT.id,
        send_chairmain_and_secretary: true,
        absence_content: this.reasonAbsence.description,
        user_absence_name: userReason,
      }
      this.SEND_EMAIL_WITH_TEMPLATE_CONFIG(data)
    },
    onClickAttendance: function () {
      this.$refs['modal-user-attendance'].show()
    },
    onSaveAttendance: function() {
      let data = {
        id: this.GET_CURRENT_ACCOUNT.id,
        event: this.event_id
      }
      this.UPDATE_ATTENDANCE_STATUS(data)
        .then(
          function() {
            this.funCheckCurrentUserNoAttendance()
            this.sendNotificationUserAttendance()
            // this.sendEmail()
            this.ON_SHOW_TOAST({
              message: "Success",
              styleType: "success"
            });
          }.bind(this)
        )
        .catch(
          function(error) {
            this.ON_SHOW_TOAST({
              message: error.response.data.message,
              styleType: "danger"
            });
          }.bind(this)
        );
      this.$refs['modal-user-attendance'].hide()
    },
    funCheckCurrentUserNoAttendance: function () {
      let filter = {
        params: {
          event: this.event_id
        }
      }
      this.CHECK_CURRENT_USER_NO_ATTENDANCE(filter)
        .then(
          function(res) {
            this.checkCurrentUserNoAttendance = res.data.value
          }.bind(this)
        )
    },
    sendNotificationUserAttendance(userData){
      let data = {
        event_id: this.event_id,
        // message_title: this.event.name,
        // message_body: this.$t('notification.attendance.attendanceNotification', {'0': userData.name}),
        user_id: userData.id,
        send_chairmain_and_secretary: true,
        action_code: TemplateCategoriesEnum.ATTENDANCE,
        template_type: Enum.TemplateType.Notification,
        full_name: userData.name,
        notification_type_code: Enum.NotificationType.Attendance
      }
      this.SEND_NOTIFICATION_IN_MEETING(data)
    },
    ...mapActions([
      "GET_EVENT_DETAIL",
      "ON_SHOW_TOAST",
      "ADD_REASON_ABSENCE",
      "GET_REASON_ABSENCE_DETAIL",
      "UPDATE_REASON_ABSENCE",
      "SEND_NOTIFICATION_IN_MEETING",
      "SEND_SMS_IN_MEETING",
      "SEND_EMAIL_WITH_TEMPLATE_CONFIG",
      "CHECK_CURRENT_USER_NO_ATTENDANCE",
      "UPDATE_ATTENDANCE_STATUS"
    ])
  },
  mounted() {
    let event_id = sessionStorage.getItem("event_id");
    this.event_id = event_id
    let event = {
      params:{
        is_meeting: true
      },
      id: event_id
    }
    this.GET_EVENT_DETAIL(event);
    const document = {
      params: {
        event: event_id
      }
    };
    this.GET_REASON_ABSENCE_DETAIL(document);
    this.funCheckCurrentUserNoAttendance()
  }
};
</script>
<style lang="scss" scoped>
@import "../../style/color.scss";
.location p {
  padding-bottom: 0;
  margin-bottom: 0;
  font-size: 14px;
}
.pdf_file {
  text-align: center;
}
.expand-color {
  color: $primary !important;
}
.send-reason-absence {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.3, $light-primary),
    color-stop(0.7, $primary)
  );
}
.invitation_img{
  max-width: 100%;
  cursor: pointer;
}
</style>